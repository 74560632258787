<template>
  <ul class="brand-style-2">
    <li v-for="(brand, i) in brandImages" :key="i">
      <a :href="brand.url">
        <img :src="brand.src" alt="Logo Images" />
      </a>
    </li>
  </ul>
</template>

<script>
  export default {
    data() {
      return {
        brandImages: [
          {
            src: require("../../assets/images/brand/1.png"),
            url: "https://www.davidweekleyhomes.com/new-homes/tx/austin?utm_medium=cpc&utm_source=google&utm_campaign=BRAND-CITY%2BRADIUS-TX-*AUSTIN*-GENERAL-DWH-SEARCH&utm_term=david+weekley+homes+austin&gclid=Cj0KCQjwp86EBhD7ARIsAFkgakj1Y62r8Dnujgz1BXcSJIUq_2e8SClFGZZrWr-uSNodZwH1dfoN0MUaAiDREALw_wcB&gclsrc=aw.ds",
          },
          {
            src: require("../../assets/images/brand/2.png"),
            url: "https://www.scottfelderhomes.com/",
          },
          {
            src: require("../../assets/images/brand/sitterle-logo2.png"),
            url: "https://sitterlehomes.com/",
          },
        ],
      };
    },
  };
</script>
